export default {
  id: "my_profile_page",
  type: "page",
  classes: "behavior-no-editor behavior-no-fav behavior-no-pin",
  content: [
    {
      apis: {
        data: {
          type: "get",
          url: "{_context->path}",
        },
        submit: {
          type: "post",
          url: "{_context->path}",
          params: {
            action: "update_fields",
          },
          success: {
            message: "Profile updated successfully",
            action: {
              type: "navigation",
              name: "BACK",
            },
          },
        },
      },
      definition: {
        actions: [],
        fields: [
          {
            name: "username",
            label: "Username",
            display: {
              width: "6",
            },
            type: "text",
            disabled: true,
          },
          {
            name: "nickname",
            label: "Nickname",
            type: "text",
            display: {
              width: "6",
            },
          },
          {
            name: "first_name",
            label: "First Name",
            type: "text",
            display: {
              width: "6",
            },
          },
          {
            name: "last_name",
            label: "Last Name",
            type: "text",
            display: {
              width: "6",
            },
          },
          {
            name: "image",
            source: "file",
            label: "Image",
            type: "image",
            display: {
              embed: {
                width: "240",
              },
              mode: "avatar",
              attributes: {
                avatar: {
                  width: "180px",
                },
              },
            },
          },
        ],
      },
      type: "form",
      title: "Profile",
    },
  ],
};

<template>
  <div aut-user-profile-page aut-home-page>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails && currentContext"
      :page="flowDetails"
      :context="currentContext"
    />
  </div>
</template>

<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";
import profile_page from "./profile_page.js";
const debug = require("debug")("atman.components.profile_page"); // eslint-disable-line
export default {
  name: "MyProfile",
  mixins: [systemPageMixin],
  data() {
    return {};
  },
  mounted() {
    debug(`in mounted of my profile page`);
    this.renderPage();
  },
  methods: {
    renderPage() {
      const component = this;
      const url = `/users/me?page=home`;
      component.currentContext = createContext(url);
      component.flowDetails = clone(profile_page);
    },
  },
};
</script>
